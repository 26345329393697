import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth/index.js'
import friends from './friends/index.js'
import table from './table/index.js'
import restaurants from './restaurants/index.js'
import profile from './profile/index.js'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    friends,
    table,
    restaurants,
    profile,
  },
  state: {
    modals: {
        inviteFriends: false,
        deleteTable: false,
        deleteUser: false,
        existingTable: false,
        joinTable: false,
    },
    showSidebar: false,
    showWelcomeMessage: false,
    showVerifiedMessage: false,
    showResetPasswordMessage: false,
    userLocation: null,
    notifications: [],
    pusher: null,
    scrollUpMenu: null
  },
  mutations: {
    scrollUpMenu(state, value){
      state.scrollUpMenu = value;
    },
    setSidebar(state, value){
      state.showSidebar = value;
    },
    SET_WELCOME(state, value){
      state.showWelcomeMessage = value;
    },
    SET_VERIFY_MESSAGE(state, value){
      state.showVerifiedMessage = value;
    },
    SET_RESET_PASSWORD_MESSAGE(state, value){
      state.showResetPasswordMessage = value;
    },
    SET_NOTIFICATIONS(state, notifications){
      state.notifications = notifications;
    },
    READ_NOTIFICATION(state, uuid){
      state.notifications = state.notifications.map( notification => {
        if (notification.uuid === uuid){
          notification.read = true;
        }
        return notification;
      });
    },
    SET_USER_LOCATION(state, data){
      state.userLocation = data;
    },
    updateModal(state, data){
        state.modals[data.modal] = data.value;
    },
    updatePusher(state, pusher){
        state.pusher = pusher;
    },
  },
  actions: {
    async resendEmailVerification(){
      return await axios.post('/email/verification-notification');
    },
    async submitFeedback( context, feedback ){
      return await axios.post(`/feedback`, feedback).then( r => {
        return r.data;
      })
    },
    async getNotifications( context ){
      return await axios.get(`/notifications`).then( r => {
        context.commit('SET_NOTIFICATIONS', r.data);
      })
    },
    async readNotification( context, uuid ){
      context.commit('READ_NOTIFICATION', uuid );
      return await axios.post(`/notifications/${uuid}/read`);
    },

  }
})
