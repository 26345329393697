<template>
	<div class="h-full bg-red-500 text-white absolute left-0 w-3/4 z-10 px-8 py-7 flex flex-col" v-if="showSidebar">
		<div class="w-full">
			<button @click="showSidebar=false">
				<font-awesome-icon icon="fa-solid fa-close" class="text-white text-xl"/>
			</button>
		</div>
		<div class="flex items-center pt-8" v-if="user">
			<user-profile-picture 
			:user="user"
			:size="12"
			:centerImage="false"
			/>
			<p class="ml-4 font-gothic">{{ currentUserName }}</p>
		</div>
		<div class="flex flex-col pt-8 font-gothic space-y-6 flex-1">
			<router-link :to="{ name: 'dashboard' }">
				<font-awesome-icon icon="fa-solid fa-house" class="text-white mr-4"/>
				<span>Dashboard</span>
			</router-link>
			<router-link :to="{ name: 'friends' }">
				<font-awesome-icon icon="fa-solid fa-users" class="text-white mr-4"/>
				<span>Friends</span>
			</router-link>
			<router-link :to="{ name: 'notifications' }">
				<font-awesome-icon icon="fa-solid fa-bell" class="text-white mr-4"/>
				<span>Notifications</span>
			</router-link>
			<router-link :to="{ name: 'profile' }">
				<font-awesome-icon icon="fa-solid fa-user" class="text-white mr-4"/>
				<span>Profile</span>
			</router-link>
			<router-link :to="{ name: 'feedback' }">
				<font-awesome-icon icon="fa-solid fa-comments" class="text-white mr-4"/>
				<span>Feedback</span>
			</router-link>
		</div>
		<div class="font-gothic flex-0 pb-8">
			<a href="#" @click="logout">
				<font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" class="text-white mr-4"/>
				<span>Logout</span>
			</a>
		</div>
	</div>
</template>

<script type="text/javascript">
	import UserProfilePicture from '@/components/UserProfilePicture.vue';
	export default {
		components: {
			UserProfilePicture,
		},
		methods: {
			async logout(){
				await this.$store.dispatch('auth/logout')
				this.$router.push({ name: 'welcome' })
			}
		},	
		mounted () {
			this.popupItem = this.$el
		},
		computed: {
			user(){
				return this.$store.state.auth.user;
			},
			currentUserName(){
				return this.user.name; 
			},
			showSidebar: {
				get(){
					return this.$store.state.showSidebar;
				},
				set(value){
					this.$store.commit('setSidebar', value);
				}
			},
		}
	}
</script>