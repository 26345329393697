<template>
	<div class="h-screen w-full z-10 absolute bottom-0 left-0 bg-gray-600 px-8" v-if="scrollUpMenu">
		<div class="h-screen flex flex-col space-y-4 relative py-12">
			<div class="flex-1"></div>
			<div v-for="(button,i) in scrollUpMenu.buttons" :key="i">
				<button 
					class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg"
					@click.stop.prevent="handleAction(button)"
				>
				<font-awesome-icon v-if="button.icon" :icon="button.icon" class="text-white mr-2"/> {{ button.label }}
			</button>
			</div>
			<button class="bg-white text-red-500 py-2 rounded-xl w-full font-gothic text-lg" @click.stop.prevent="reset">
				Cancel
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data(){
			return {

			}
		},
		methods: {
			reset(){
				this.$store.commit('scrollUpMenu', null);
			},
			handleAction(button){
				if (button.action === 'function'){
					button.payload();
					this.reset();
				}
			}
		},
		computed: {
			scrollUpMenu(){
				return this.$store.state.scrollUpMenu;
			}
		}
	}
</script>