import axios from 'axios'

export default {
  namespaced: true,
  state: {
    friends: [],
    requests_received: [],
    requests_sent: [],
  },
  mutations: {
    SET_FRIENDS(state, friends){
      state.friends = friends;
    },
    SET_REQUESTS_RECEIVED(state, requests_received){
      state.requests_received = requests_received;
    },
    SET_REQUESTS_SENT(state, requests_sent){
      state.requests_sent = requests_sent;
    },
  },
  actions: {
    async getFriends( { commit } ){
      return await axios.get(`/friends`).then( r => {
        commit('SET_FRIENDS', r.data.friends);
        commit('SET_REQUESTS_RECEIVED', r.data.requests_received);
        commit('SET_REQUESTS_SENT', r.data.requests_sent);
      })
    },
    async searchFriends(context, keywords){
      return await axios.post(`/friends/search`, {
        keywords
      }).then( r => {
        return r;
      })
    },
    async sendFriendRequest(context, uuid){
      return await axios.post(`/friends/${uuid}/invite`).then( r => {
        return r.data;
      })
    },
    async acceptFriendRequest(context, uuid){
      return await axios.post(`/friends/${uuid}/accept`).then( r => {
        return r.data;
      })
    },
    async denyFriendRequest(context, uuid){
      return await axios.post(`/friends/${uuid}/deny`).then( r => {
        return r.data;
      })
    },
    async unfriend(context, uuid){
      return await axios.post(`/friends/${uuid}/unfriend`).then( r => {
        return r.data;
      })
    },
  }
  
}