import axios from 'axios'

export default {

  namespaced: true,

  state: {
    table: null,
    table_friends: [],
    tables: [],
    cuisines: [],
    messages: [],
  },

  getters: {

  },

  mutations: {
    SET_MESSAGES(state, messages){
      state.messages = messages;
    },
    SET_ANSWER(state, data){
      state.table.answers[data.name] = data.value;
    },
    SET_TABLES(state, tables){
      state.tables = tables;
    },
    SET_TABLE(state, value){
      state.table = value;
      if (state.table && state.table.answers.cuisines){
        state.table.answers.cuisines = JSON.parse(state.table.answers.cuisines);
      }
      if (state.table && state.table.answers.address){
        state.table.answers.address = state.table.answers.address !== "myLocation" ? JSON.parse(state.table.answers.address) : null;
      }
    },
    SET_STEP(state, data){
      state.table.answers.step = data;
    },
    SET_CUISINES(state, cuisines){
      state.cuisines = cuisines;
    },
    addTableFriend(state, friend){
        let statuses = ['complete', 'incomplete'];
        let randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
        if (!state.table_friends.find( x => x.id === friend.id)){
            state.table_friends.push({...friend, status: randomStatus});
        }
    },
    removeTableFriend(state, friend){
      state.table_friends = state.table_friends.filter( x => x.id !== friend.id);
    },
  },

  actions: {
    async createTable(){
      return await axios.post('/table');
    },
    async getTable( { commit, state }, data ){
      return await axios.get(`/table/${data.uuid}`).then( r => {
        commit('SET_TABLE', r.data);
        if (state.table.status === 'done'){
          commit('SET_STEP', 7);
        }
      })
    },
    async getCuisines( { commit } ){
      return await axios.get(`/cuisines`).then( r => {
        commit('SET_CUISINES', r.data);
      })
    },
    async checkTable(){
      return await axios.get(`/table`).then( r => {
        return r.data;
      })
    },
    async joinTable( context, code ){
      return await axios.post(`/table/${code}/join`).then( r => {
        return r.data;
      })
    },
    async deleteTable( { state }, payload){
      let uuid = payload && payload.uuid && payload.uuid.length ? payload.uuid : state.table.uuid;
      return await axios.post(`/table/${uuid}/delete`);
    },
    async getTables( { commit } ){
      return await axios.get(`/tables`).then( r => {
        commit('SET_TABLES', r.data);
      })
    },
    async saveAnswers( { state, commit }, payload ){
      return await axios.post('/table/answers', 
        { table: state.table, step: payload.step }, 
        { headers: { 'Content-Type': 'application/json' } }
      ).then( () => {
        commit('SET_STEP', payload.step);
      });
    },
    async submitAnswers( { state, commit }, payload ){
      return await axios.post('/table/finish', 
        { table: state.table }, 
        { headers: { 'Content-Type': 'application/json' } }
      ).then( () => {
        commit('SET_STEP', payload.step);
      });
    },
    async postChat(context, data){
      return await axios.post(`/chat/${data.uuid}`, 
        { message: data.message }, 
        { headers: { 'Content-Type': 'application/json' }
      });
    },
    async saveTableName(context, data){
      return await axios.post(`/table/${data.table_uuid}/update-name`, { 
        table_uuid: data.table_uuid,
        name: data.name
      });
    },
    async generateTableName(){
      return await axios.get(`/table/generate-name`);
    },
    async getChat( { commit }, uuid ){
      return await axios.get(`/chat/${uuid}`).then( r => {
        commit('SET_MESSAGES', r.data);
      })
    },
    async inviteToTable(context, data){
      return await axios.post(`/table/${data.table}/invite-friend`, {
        user: data.user,
      }).then( r => {
        context.dispatch('getTable', {
          uuid: context.state.table.uuid
        });
        return r.data;
      })
    },
    async removeFromTable(context, data){
      return await axios.post(`/table/${data.table}/remove-friend`, {
        user: data.user,
      }).then( () => {
        context.dispatch('getTable', {
          uuid: context.state.table.uuid
        });
      })
    },
    async acceptInvite(context, data){
      return await axios.post(`/table/${data.table_uuid}/accept-invite`, {
        user: data.user_uuid,
      }).then( r => {
        context.dispatch('getTable', {
          uuid: data.table_uuid
        });
        context.dispatch('getTables');
        return r.data;
      })
    },
    async declineInvite(context, data){
      return await axios.post(`/table/${data.table_uuid}/decline-invite`, {
        user: data.user_uuid,
      }).then( r => {
        context.dispatch('getTables');
        return r.data;
      })
    },
  }
  
}