import axios from 'axios'

export default {
  namespaced: true,
  state: {
    favorites: []
  },
  mutations: {
    SET_FAVORITE_RESTAURANTS(state, value){
      state.favorites = value;
    }
  },
  actions: {
    async getFavoriteRestaurants( context ){
      return await axios.get(`/restaurant/favorites`).then( r => {
        context.commit('SET_FAVORITE_RESTAURANTS', r.data);
      })
    },
    async toggleFavorite( context, payload ){
      let url = payload.favorite ? "favorite" : 'unfavorite';
      return await axios.post(`/restaurant/${payload.uuid}/${url}`);
    },
    async getRestaurant( context, payload ){
      return await axios.get(`/restaurant/${payload.uuid}`);
    },
    async fetchRestaurantPhotos(context, uuid){
      return await axios.get(`/restaurant/${uuid}/photos`);
    },
  }
}
