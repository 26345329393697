import axios from 'axios'

export default {

  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    token: null,
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },
    user (state) {
      return state.user
    },
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
    SET_USER (state, value) {
      state.user = value
    },
    SET_TOKEN (state, value) {
      state.token = value
      localStorage.setItem('hungry+token', value);
    }
  },

  actions: {
    async register ({ commit }, credentials) {
      let response = await axios.post('/register', credentials)
      commit('SET_TOKEN', response.data.token)
      return response;
    },
    async login ({ commit }, credentials) {
      let response = await axios.post('/login', credentials)
      commit('SET_TOKEN', response.data.token)
      return response;
    },
    async logout ({ commit }) {
      commit('SET_TOKEN', null)
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      localStorage.setItem('hungry+token', null);
      return await axios.post('/logout')
    },
    async getCurrentUser ({ commit }) {
      return axios.get('/user').then( response => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data.user)
      })
      .catch( () => {
        commit('SET_TOKEN', null)
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
        localStorage.setItem('hungry+token', null);
      });
    },
    async forgotPassword (context, payload) {
      return await axios.post('/forgot-password', payload);
    },
    async resetPassword (context, payload) {
      return await axios.post('/reset-password', payload);
    },
    async checkEmail (context, email) {
      return await axios.post('/email/check', email);
    }
  }

}