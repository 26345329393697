import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    redirect: '/dashboard' 
  },
  { 
    path: '/welcome',
    name: 'welcome', 
    component: () => import(/* webpackChunkName: "welcome" */ './views/auth/Welcome.vue'),
  },
  { 
    path: '/forgot-password',
    name: 'forgot-password', 
    component: () => import(/* webpackChunkName: "forgot-password" */ './views/auth/ForgotPassword.vue'),
  },
  { 
    path: '/reset-password',
    name: 'reset-password', 
    component: () => import(/* webpackChunkName: "reset-password" */ './views/auth/ResetPassword.vue'),
  },
  { 
    path: '/dashboard',
    name: 'dashboard', 
    component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
  },
  { 
    path: '/table/:uuid',
    component: () => import(/* webpackChunkName: "table" */ './views/table/Table.vue'),
    name: 'table',
    children: [
      { 
        path: 'invite',
        name: 'table-invite',
        component: () => import(/* webpackChunkName: "table-invite" */ './views/table/TableInvite.vue'),
      },
      { 
        path: 'friends',
        name: 'table-friends',
        component: () => import(/* webpackChunkName: "table-friends" */ './views/table/TableFriends.vue'),
      },
      { 
        path: 'food',
        name: 'table-food',
        component: () => import(/* webpackChunkName: "table-food" */ './views/table/TableFood.vue'),
      },
      { 
        path: 'location',
        name: 'table-location',
        component: () => import(/* webpackChunkName: "table-location" */ './views/table/TableLocation.vue'),
      },
      { 
        path: 'price',
        name: 'table-price',
        component: () => import(/* webpackChunkName: "table-price" */ './views/table/TablePrice.vue'),
      },
      { 
        path: 'summary',
        name: 'table-summary',
        component: () => import(/* webpackChunkName: "table-summary" */ './views/table/TableSummary.vue'),
      },
      { 
        path: 'lobby',
        name: 'table-lobby',
        component: () => import(/* webpackChunkName: "table-lobby" */ './views/table/TableLobby.vue'),
      },
      { 
        path: 'chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "chat" */ './views/table/TableChat.vue'),
      },
      { 
        path: 'restaurant/:restaurant_uuid',
        name: 'table-restaurant',
        component: () => import(/* webpackChunkName: "table-restaurant" */ './views/restaurant/RestaurantDetails.vue'),
      },
    ]
  },
  { 
    path: '/restaurant/:restaurant_uuid',
    name: 'restaurant',
    component: () => import(/* webpackChunkName: "restaurant-details" */ './views/restaurant/RestaurantDetails.vue'),
  },
  { 
    path: '/user/:uuid',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ './views/profile/User.vue'),
  },
  { 
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ './views/profile/Profile.vue'),
  },
  { 
    path: '/edit-profile',
    name: 'edit-profile',
    component: () => import(/* webpackChunkName: "edit-profile" */ './views/profile/EditProfile.vue'),
  },
  { 
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ './views/profile/ChangePassword.vue'),
  },
  { 
    path: '/notification-settings',
    name: 'notification-settings',
    component: () => import(/* webpackChunkName: "notification-settings" */ './views/profile/NotificationSettings.vue'),
  },
  { 
    path: '/friends',
    name: 'friends',
    component: () => import(/* webpackChunkName: "friends" */ './views/friends/Friends.vue'),
    redirect: '/friends',
    children: [
      { 
        path: '',
        name: 'friends-list',
        component: () => import(/* webpackChunkName: "friends-list" */ './views/friends/FriendsList.vue'),
      },
      { 
        path: 'requests',
        name: 'friends-requests',
        component: () => import(/* webpackChunkName: "friends-requests" */ './views/friends/FriendsRequests.vue'),
      }
    ]
  },
  { 
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ './views/profile/Notifications.vue'),
  },
  { 
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "feedback" */ './views/profile/Feedback.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
