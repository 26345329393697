import axios from 'axios';
import store from '../store'
import router from '../router'

export function axiosSetup() {

    axios.interceptors.request.use( 
        config => {

            // Prepends the base_url to every endpoint request
            config.url = process.env.VUE_APP_API_BASE_URL + config.url;

            // Always sends the session along with requests
            // config.xsrfCookieName = "XSRF-TOKEN",
            // config.xsrfHeaderName = "X-XSRF-TOKEN"

            if(store.state.auth.token){
                config.headers.authorization = `Bearer ${store.state.auth.token}`;
            }

            return config;

        }, 
        error => {
            console.error('interceptor error');
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            // console.log(response);
            return response;
        },
        error => {

            if (error.response && error.response.status === 401 && !['welcome','reset-password'].includes(router.currentRoute.name)){
                router.push({ name: 'welcome' })
                return;
            }
            
            if (    error.response && 
                    error.response.status === 403 && 
                    error.response.data && 
                    error.response.data.message === 'Your email address is not verified.'
                ){
                store.commit('SET_VERIFY_MESSAGE', true)
                if (router.currentRoute.name !== 'welcome'){
                    router.push({ name: 'welcome' })
                }
            }

            return Promise.reject(error);
            // console.error(error);

        },
    )
}