<template>
  <div 
    id="app" 
    class="flex justify-center h-full"
  >
    <div 
      id="phone_viewport"
      class="px-8 text-gray-500 h-screen w-screen relative" 
      :class="{
        'bg-gray-50': !isBackgroundRed,
        'bg-red-500': isBackgroundRed,
        'pt-6': isNative
      }
    ">
      <sidebar/>
      <scroll-up-menu/>
      <router-view/>
    </div>
  </div>
</template>

<script type="text/javascript">
  import Sidebar from '@/components/Sidebar.vue';
  import ScrollUpMenu from '@/components/ScrollUpMenu.vue';
  export default {
    watch: {
      '$route'() {
        this.$store.commit('setSidebar', false);
      }
    },
    data(){
      return {
        isNative: false
      }
    },
    components: {
      Sidebar,
      ScrollUpMenu
    },
    methods: {
      localToken(){
        let token = localStorage.getItem('hungry+token');
        if (token){
          this.$store.commit('auth/SET_TOKEN', token);
        }
      },
      setViewHeight() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      },
      setupPusher(){
        // Pusher.logToConsole = true;
        /*global Pusher*/
        this.$store.commit('updatePusher', new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
          cluster: 'us2'
        }));
      },
      setupNotificationsPusher(){
        this.channel = this.$store.state.pusher.subscribe(`user-${this.user.id}`);
        this.channel.bind('get-notifications', () => {
          this.$store.dispatch('getNotifications');
        });
      },
    },
    created(){
      if (this.$route && this.$route.path === "/_=_") {
        this.$router.push({ name: 'dashboard' });
      }
    },
    computed: {
      isBackgroundRed(){
        return this.$route.name === 'table-created'
      },
      user(){
        return this.$store.state.auth.user;
      },
    },
    mounted() {

      this.localToken();

      this.$store.dispatch('table/getCuisines');
      this.$store.dispatch('restaurants/getFavoriteRestaurants');
      this.$store.dispatch('auth/getCurrentUser').then( () => {
        this.setupPusher();
        this.setupNotificationsPusher();
      });
      this.$store.dispatch('getNotifications');
      this.setViewHeight()
      window.addEventListener('resize', () => {
        this.setViewHeight()
      })
      if (window.navigator.userAgent.indexOf('iPhone') > 0){
        this.isNative = true;
      }
    },
  }
</script>

<style type="text/css">
  #phone_viewport {
    max-width: 400px !important;
  }
</style>