import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './main.css'
import { axiosSetup } from './helpers/axios-setup.js';

// font awesome icons - SOLID
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faMugHot } from '@fortawesome/free-solid-svg-icons'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { faHourglass } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faTableColumns } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faRotate } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { faPersonRunning } from '@fortawesome/free-solid-svg-icons'

// font awesome icons - REGULAR
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { faComment as farComment } from '@fortawesome/free-regular-svg-icons'
import { faComments as farComments } from '@fortawesome/free-regular-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import { faBell as farBell } from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faBars,
  faEnvelope,
  faBell,
  farBell,
  faMagnifyingGlass,
  faMugHot,
  faEllipsisVertical,
  faHourglass,
  faThumbsUp,
  faXmark,
  faCheck,
  faLocationArrow,
  faPencil,
  faLocationDot,
  faComments,
  faComment,
  farComments,
  farComment,
  faPhone,
  faMapLocationDot,
  faArrowRightFromBracket,
  faHouse,
  faUser,
  faUsers,
  faTableColumns,
  faCircle,
  faCircleNotch,
  faCircleQuestion,
  faCircleMinus,
  faClock,
  faRotate,
  faEnvelopeOpenText,
  farStar,
  faStar,
  faExclamationCircle,
  faTrashCan,
  faPersonRunning,
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

axiosSetup();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
