<template>
	<div>
		<img 
			v-if="user && user.profile_picture" 
			:class="profilePictureClass" 
			class="rounded-full object-cover"
			:src="imageSrc" 
			:alt="initials"
		>
		<div
			v-else
			class="rounded-full text-gray-100 flex items-center justify-center" 
			:class="noProfilePictureClass"
			:style="defaultStyle"
		>
			<div>{{ initials }}</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			size: {
				type: Number,
				default: 8
			},
			user: {
				type: Object
			},
			centerImage: {
				type: Boolean,
				default: true
			},
		},
		computed: {
			profilePictureClass(){
				let classes = [];
				classes.push(`h-${this.size} w-${this.size}`);  
				classes.push(this.centerImage ? "mx-auto" : "")
				return classes.join(" ");
			},
			noProfilePictureClass(){
				let classes = [];
				classes.push(`h-${this.size} w-${this.size}`);  
				classes.push(this.size < 12 ? "text-sm" : "text-lg");
				classes.push(this.size <= 12 ? "text-sm" : "text-4xl");
				classes.push(this.centerImage ? "mx-auto" : "")
				return classes.join(" ");
			},
			imageSrc(){
				return this.showPreview ? this.imagePreview : this.user.profile_picture;
			},
			initials(){
				return this.user ? this.user.name.charAt(0) : '';
			},
			defaultStyle(){
				return {
					'background-color': this.user ? this.user.profile_color : ''
				}
			}
		}
	}
</script>



