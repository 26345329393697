import axios from 'axios'

export default {
  namespaced: true,
  actions: {
    async getUser(context, data){
      return axios.get(`/user/${data.uuid}`);
    },
    async updateUser(context, data){
      return axios.post('/user', data);
    },
    async updateUserProfilePicture(context, data){
      return axios.post('/user-profile-picture', data);
    },
    async updatePassword(context, data){
      return axios.post('/user/password', data);
    },
    async deleteUser(){
      return await axios.post(`/user/delete`);
    },
  }
}
